/**
 * 邮箱
 * @param {*} s
 */
export function isEmail (s) {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile (s) {
  return /^1[0-9]{10}$/.test(s)
}

/**
 * 电话号码
 * @param {*} s
 */
export function isPhone (s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL (s) {
  return /^http[s]?:\/\/.*/.test(s)
}
// 日期转换 
export function dateFormat(val, type){
  let time = ''
  if (val !== null) {
    const date = new Date(val)
    const year = date.getFullYear()
    var month = date.getMonth() + 1
    month = month > 9 ? month : '0' + month
    var day = date.getDate()
    day = day > 9 ? day : '0' + day
    var hh = date.getHours()
    hh = hh > 9 ? hh : '0' + hh
    var mm = date.getMinutes()
    mm = mm > 9 ? mm : '0' + mm
    var ss = date.getSeconds()
    ss = ss > 9 ? ss : '0' + ss
    var ms = date.getMilliseconds()
    if (type === 'fullTime') {
      time = year + '年' + month + '月' + day + '日 ' + hh + '点' + mm + '分'
    } else if (type === 'time') {
      time = hh + '点' + mm + '分' + ss + '秒'
    } else if (type === 'day') {
      time = year + '年' + month + '月' + day + '日'
    } else if (type === 'langFullTime') {
      time = year + '年' + month + '月' + day + '日 ' + hh + '点' + mm + '分' + ss + '秒' + ms + '毫秒'
    }
  }
  return time
}

